import * as Yup from 'yup';

import { Formik } from 'formik';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
  Zoom
} from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import postWaitlist from 'src/services/createWaitlistItem';
import { useSnackbar } from 'notistack';

const Waitlist = () => {
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        company: '',
        designation: '',
        terms: false,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        name: Yup.string()
          .min(2)
          .max(255)
          .required(t('The name field is required')),
        company: Yup.string()
          .min(3)
          .max(255)
          .required(t('The company field is required')),

        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          const res = await postWaitlist(values);

          if (!res.success) throw new Error(res.message);

          enqueueSnackbar(res.message, {
            variant: 'success',
            TransitionComponent: Zoom
          });
          resetForm();

          if (isMountedRef.current) {
            setSubmitting(false);
            setStatus({ success: true });
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            enqueueSnackbar(err.message, {
              variant: 'error',
              TransitionComponent: Zoom
            });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.name && errors.name}
            label={t('Full Name')}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            type="name"
            value={values.name}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            helperText={touched.email && errors.email}
            label={t('Email address')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />

          <TextField
            error={Boolean(touched.designation && errors.designation)}
            fullWidth
            margin="normal"
            helperText={touched.designation && errors.designation}
            label={t('Designation')}
            name="designation"
            onBlur={handleBlur}
            onChange={handleChange}
            type="designation"
            value={values.designation}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.company && errors.company)}
            fullWidth
            margin="normal"
            helperText={touched.company && errors.company}
            label={t("Company's Name")}
            name="company"
            onBlur={handleBlur}
            onChange={handleChange}
            type="company"
            value={values.company}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            />
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Submit')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Waitlist;
