import BackupTableIcon from '@mui/icons-material/BackupTableRounded';
// import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import ShortlistedIcon from '@mui/icons-material/ClassRounded';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletRounded';

const menuItems = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: BackupTableIcon,
        link: 'dashboard'
      },
      // {
      //   name: 'Analytics',
      //   icon: AnalyticsRoundedIcon,
      //   link: 'analytics'
      // },
      {
        name: 'Shortlisted',
        icon: ShortlistedIcon,
        link: 'shortlisted'
      },
      {
        name: 'Request Analytics',
        icon: WalletIcon,
        link: 'request'
      },
      {
        name: 'Your Requests',
        icon: WalletIcon,
        link: 'my-requests'
      }
    ]
  }
];

export default menuItems;
