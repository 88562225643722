import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const RequestAnalyticList = Loader(
  lazy(() => import('src/content/pages/Application/RequestList'))
);

const requestAnalyticsRoutes = [
  {
    path: '/',
    element: <RequestAnalyticList />
  }
];

export default requestAnalyticsRoutes;
