const { default: axios } = require('axios');

const baseUrl = process.env.REACT_APP_BLOCKWAY_API_URL;

const postWaitlist = async (payload) => {
  const { data } = await axios
    .post(`${baseUrl}/waitlist`, payload)
    .catch((err) => console.log(err.message));

  return data;
};

export default postWaitlist;
