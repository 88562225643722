import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';

import dashboardsRoutes from './dashboard';
import analyticsRoutes from './analytics';
import shortlistedRoutes from './shortlisted';
import accountRoutes from './account';
import baseRoutes from './base';
import requestAnalyticsRoutes from './requestAnalytics';
import requestListRoutes from './requestList';

const router = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'dashboard',
        children: dashboardsRoutes
      },
      {
        path: 'analytics',
        children: analyticsRoutes
      },
      {
        path: 'shortlisted',
        children: shortlistedRoutes
      },
      {
        path: 'request',
        children: requestAnalyticsRoutes
      },
      {
        path: 'my-requests',
        children: requestListRoutes
      }
    ]
  }
];

export default router;
