import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Crypto = Loader(lazy(() => import('src/content/dashboard')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Crypto />
  }
];

export default dashboardsRoutes;
