import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Analytics = Loader(
  lazy(() => import('src/content/pages/Application/Analytics'))
);

const DetailedAnalytics = Loader(
  lazy(() => import('src/content/pages/Application/DetailedAnalytics'))
);

const analyticsRoutes = [
  {
    path: '/',
    element: <Analytics />
  },
  { path: '/:address', element: <DetailedAnalytics /> }
];

export default analyticsRoutes;
