import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Shortlisted = Loader(
  lazy(() => import('src/content/pages/Application/Shortlisted'))
);

const analyticsRoutes = [
  {
    path: '/',
    element: <Shortlisted />
  }
];

export default analyticsRoutes;
