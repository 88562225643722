import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Typography,
  Container,
  styled,
  IconButton,
  Tooltip,
  Divider,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import Logo from 'src/components/LogoSign';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailRoundedIcon from '@mui/icons-material/MailRounded';

import Waitlist from './Waitlist';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 500px;
  background: ${theme.colors.gradients.blue3};
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      background: ${theme.colors.alpha.trueWhite[10]};
`
);

const MainContent = styled(Box)(
  () => `
  padding: 0 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

function JoinWaitlist() {
  const { t } = useTranslation();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Helmet>
        <title>Join The Waitlist</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <Logo />
              <Box mt={6}>
                {/* <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 7
                  }}
                >
                  Blockway
                </TypographyH1> */}

                <TypographyPrimary
                  variant="subtitle1"
                  align="center"
                  sx={{
                    my: 3
                  }}
                >
                  {t(
                    'Blockway is the next generation on-chain analytics platform. Make the most of your project marketing by making data-driven decisions'
                  )}
                </TypographyPrimary>
              </Box>
              <DividerWrapper />
              <Box
                sx={{
                  textAlign: 'center'
                }}
              >
                <TypographyPrimary
                  variant="h5"
                  sx={{
                    my: 3
                  }}
                >
                  {t('Follow us on')}
                </TypographyPrimary>
                <Tooltip
                  arrow
                  placement="top"
                  title="LinkedIn/blockway-technologies"
                >
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        'https://www.linkedin.com/company/blockway-technologies';
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="business@blockway.tech">
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = 'mailto:business@blockway.tech';
                    }}
                  >
                    <MailRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent sx={{ pl: mobile ? 0 : '400px' }}>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Join The Waitlist')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t(
                    'We are working hard to make more and more members a part of us.'
                  )}
                </Typography>
              </Box>
              <Waitlist />
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Already have an account?')}
                </Typography>{' '}
                <Link component={RouterLink} to="/account/login">
                  <b>Sign in</b>
                </Link>
              </Box>
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default JoinWaitlist;
